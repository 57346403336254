import type { DepartmentOptionsObject } from "../../translation-keys/department-option-keys.js";

const FormDepartmentOptions: DepartmentOptionsObject = {
  "Accounting/Finance": "Comptabilité/finance",
  "Blood Bank": "Banque du sang",
  Clinical: "Clinique",
  "Customer Service": "Service client",
  "Document Control": "Contrôle des documents",
  EHS: "Hygiène et sécurité",
  Engineering: "Ingénierie",
  "Executive (C-level or VP)": "Supérieur (directeur ou directeur-adjoint)",
  "Human Resources": "Ressources humaines",
  "IT / Information Systems": "TI/systèmes d'information",
  Laboratory: "Laboratoire",
  Manufacturing: "Industrie manufacturière",
  Operations: "Opérations",
  Other: "Autre",
  Packaging: "Conditionnement",
  "Product Development": "Développement du produit",
  Purchasing: "Achats",
  Quality: "Qualité",
  "R&D": "R&D",
  "R&D QA": "QA R&D",
  Regulatory: "Règlementaire",
  "Sales / Marketing": "Ventes/marketing",
  Training: "formation",
  Validation: "Validation",
};

export default FormDepartmentOptions;
